


















import { Component, Vue } from "vue-property-decorator";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import AccountDataContext from "@/dataContexts/AccountDataContext";
import StringUtils from "@/common/utilities/StringUtils";
import AccountErrors from "@/common/AccountErrors";

@Component({
    components: {
        SpinnerComponent
    },
})
export default class ConfirmEmailView extends Vue {
    private accountDataContext: AccountDataContext = new AccountDataContext();

    private loading = false;
    private title = "";
    private subtitle = "";

    created() {
        const id = this.$route.query.id as string;
        const token = this.$route.query.token as string;
        const email = this.$route.query.email as string;

        if (id && token) {
            this.loading = true;

            this.accountDataContext.confirmEmail(id, token, email).then(x => {
                this.loading = false;

                if (x.successfully) {
                    this.title = "view.confirmEmail.successfully";
                } else {
                    this.title = "view.confirmEmail.errored";

                    if (x.data && StringUtils.equalsIgnoreCase(x.data, AccountErrors.InvalidToken)) {
                        this.subtitle = "view.confirmEmail.invalidToken";
                    }
                }
            });
        }
    }
}
